/* eslint-disable react/no-unknown-property */
import { Suspense, useEffect, useRef, useState } from 'react';
import type { AbstractMesh, AnimationGroup, ArcRotateCamera, PBRMaterial, Scene as SceneType } from '@babylonjs/core';
import { Texture } from '@babylonjs/core';
import { StandardMaterial } from '@babylonjs/core';
import { Vector3, Color3, Color4, GlowLayer, CubeTexture, ActionManager, ExecuteCodeAction } from '@babylonjs/core';
import type { SceneEventArgs } from 'react-babylonjs';
import { Model, Scene } from 'react-babylonjs';
import '@babylonjs/loaders/glTF';
import '@babylonjs/core/Debug/debugLayer';
import '@babylonjs/inspector';

import { baseParticleSystem, boxParticleSystem } from './particles';
import { godrays } from './godrays';
import { cardAnimation } from './animations';

const CARD_WIDTH = 0.14;
const CARD_HEIGHT = CARD_WIDTH / 1.56;
const CARD_START_ROTATION = new Vector3(0, -Math.PI / 2, Math.PI);
const CARD_START_POSITION = new Vector3(0, 0.8, 0);

function GreetingCard() {
  return (
    <plane
      name="greetingCard"
      width={CARD_WIDTH}
      height={CARD_HEIGHT}
      rotation={CARD_START_ROTATION}
      position={CARD_START_POSITION}
      isVisible={false}
    ></plane>
  );
}

type CyberboxProps = {
  setLoadingOpacity: (value: number) => void;
};

function Cyberbox({ setLoadingOpacity }: CyberboxProps) {
  const [scene, setScene] = useState<SceneType>();
  const [loadRings, setLoadRings] = useState<boolean>(false);
  const sceneCamera = useRef<ArcRotateCamera>();

  const animationGroups = useRef<AnimationGroup[]>();
  const boxOpened = useRef<boolean>(false);
  const cardRef = useRef<AbstractMesh>();

  // const [counterValue, setCounterValue] = useState<number>(12);
  // const counterRef = useRef<TextBlock>();

  // useEffect(() => {
  //   if (!counterRef.current) return;
  //   counterRef.current.text = counterValue.toString();
  // }, [counterValue]);

  const setupScene = ({ scene }: SceneEventArgs) => {
    scene.useRightHandedSystem = true;
    scene.clearColor = new Color4(0.043, 0.043, 0.1, 1);
    setScene(scene);
    scene.onReadyObservable.add(() => {
      const envMap = new CubeTexture('./assets/textures/snowy.env', scene);
      scene.environmentTexture = envMap;
      scene.environmentTexture.level = 1;

      const gl = new GlowLayer('glow', scene);
      gl.intensity = 0.5;
      setTimeout(() => {
        setLoadingOpacity(0);
      }, 4000);
    });
  };

  useEffect(() => {
    setLoadRings(true);
    if (!scene) return;
    //scene.debugLayer.show();
    animationGroups.current = scene.animationGroups;
    const card = scene.getMeshByName('greetingCard');
    if (!card) return;
    cardRef.current = card;
  }, [scene]);

  const setupCamera = (camera: ArcRotateCamera) => {
    sceneCamera.current = camera;
  };

  const setupRings = (rings: AbstractMesh) => {
    if (!scene) return;
    const root = rings.getChildMeshes()[0];
    root.name = 'ringsRoot';
  };

  const createActions = (scene: SceneType) => {
    const actionManager = new ActionManager(scene);
    actionManager.registerAction(
      new ExecuteCodeAction(ActionManager.OnPickDownTrigger, function () {
        if (boxOpened.current || !animationGroups.current) return;

        boxOpened.current = true;
        const boxAnimation = animationGroups.current[1];
        boxAnimation.stop();
        boxAnimation.goToFrame(0);
        boxAnimation.start(false, 1, boxAnimation.from, boxAnimation.to);

        setTimeout(() => {
          boxParticleSystem(scene);
          if (!cardRef.current) return;
          cardRef.current.isVisible = true;
          scene.beginAnimation(cardRef.current, 0, 100, true, 1);

          setTimeout(() => {
            boxAnimation.stop();
            boxAnimation.goToFrame(100);
            boxAnimation.start(false, -1, 100, 60);
            scene.getParticleSystemById('boxParticleSystem')?.dispose();
            if (!cardRef.current) return;
            cardRef.current.isVisible = false;

            boxAnimation.onAnimationEndObservable.addOnce(() => {
              boxOpened.current = false;
            });
          }, 8000);
        }, 1600);
      }),
    );

    return actionManager;
  };

  const setupBox = (box: AbstractMesh) => {
    if (!scene) return;
    const root = box.getChildMeshes()[0];
    root.name = 'boxRoot';
    const actions = createActions(scene);

    for (const mesh of root.getChildMeshes()) {
      mesh.actionManager = actions;
      switch (mesh.name) {
        case 'baseGlow':
          {
            if (!mesh.material) return;
            const baseGlowMaterial = mesh.material as PBRMaterial;
            baseGlowMaterial.emissiveTexture = baseGlowMaterial.albedoTexture;
            baseGlowMaterial.emissiveColor = new Color3(1, 1, 1);
          }

          break;
        case 'doorsGlow':
          {
            if (!mesh.material) return;
            const doorsGlowMaterial = mesh.material as PBRMaterial;
            doorsGlowMaterial.emissiveTexture = doorsGlowMaterial.albedoTexture;
            doorsGlowMaterial.emissiveColor = new Color3(1, 1, 1);
          }
          break;
        case 'base':
          {
            if (!mesh.material) return;
            const baseMaterial = mesh.material as PBRMaterial;
            baseMaterial.metallic = 0.83;
            baseMaterial.roughness = 0.34;
          }
          break;
        case 'door':
          {
            if (!mesh.material) return;
            const doorMaterial = mesh.material as PBRMaterial;
            doorMaterial.metallic = 0.83;
            doorMaterial.roughness = 0.34;
          }
          break;
        default:
          break;
      }
    }

    if (!animationGroups.current) return;
    animationGroups.current[1].stop();
    baseParticleSystem(scene);
    if (!sceneCamera.current) return;
    godrays(sceneCamera.current, scene);

    if (!cardRef.current) return;
    const card = cardRef.current;
    const cardMaterial = new StandardMaterial('greetingCard_material', scene);
    cardMaterial.diffuseTexture = new Texture('./assets/textures/card.jpg', scene, false, false);
    cardMaterial.backFaceCulling = false;
    cardMaterial.emissiveColor = new Color3(0, 0.2117, 0.4);
    // cardMaterial.opacityTexture = new Texture('./assets/textures/card/card_a.png', scene, false, false);
    card.material = cardMaterial;
    card.animations.push(...cardAnimation(card));
  };

  return (
    <Scene
      clearColor={new Color4(0, 0.165, 0.231, 1)}
      onSceneMount={(scene: SceneEventArgs) => {
        setupScene(scene);
      }}
    >
      <arcRotateCamera
        name="arcRotateCamera"
        target={new Vector3(0, 1, 0)}
        alpha={3.7}
        beta={Math.PI / 2 - 0.4}
        radius={6}
        lowerRadiusLimit={2}
        minZ={0.01}
        panningSensibility={0}
        wheelPrecision={100}
        pinchPrecision={100}
        onCreated={(camera: ArcRotateCamera) => {
          setupCamera(camera);
        }}
      />
      <hemisphericLight
        name="hemisphericLight"
        direction={new Vector3(0, 1, 0)}
        specular={new Color3(0, 0, 0)}
        diffuse={new Color3(0.098, 0.976, 1)}
        groundColor={new Color3(0.098, 0.976, 1)}
        intensity={2}
      />

      <Suspense fallback={<plane name="plane" size={0.2}></plane>}>
        {loadRings && (
          <Model
            name="box"
            sceneFilename="box.glb"
            rootUrl="./assets/mesh/"
            scaling={new Vector3(2.2, 2.2, 2.2)}
            position={new Vector3(0, 0.7, 0)}
            isPickable={true}
            onCreated={(box: AbstractMesh) => {
              setupBox(box);
            }}
          ></Model>
        )}
        {loadRings && (
          <Model
            name="rings"
            sceneFilename="rings_s.glb"
            rootUrl="./assets/mesh/"
            scaling={new Vector3(1.2, 1.2, 1.2)}
            rotation={new Vector3(0, Math.PI, 0)}
            isPickable={false}
            onCreated={(rings: AbstractMesh) => {
              setupRings(rings);
            }}
          ></Model>
        )}
      </Suspense>
      <GreetingCard />
    </Scene>
  );
}

export default Cyberbox;
