import type { AbstractMesh } from '@babylonjs/core';
import { Animation, SineEase, EasingFunction } from '@babylonjs/core';

const easingFunction = new SineEase();
easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);

const cardAnimation = (card: AbstractMesh) => {
  const startPosition = card.position.clone();
  const startRotation = card.rotation.clone();
  const startScaling = card.scaling.clone();

  //ROTATION X
  const cardRotationX = new Animation(
    'cardRotationX',
    'rotation.x',
    60,
    Animation.ANIMATIONTYPE_FLOAT,
    Animation.ANIMATIONLOOPMODE_CONSTANT,
  );

  cardRotationX.setEasingFunction(easingFunction);

  const cardRotationXKeys = [];

  cardRotationXKeys.push({
    frame: 0,
    value: startRotation.x,
  });
  cardRotationXKeys.push({
    frame: 100,
    value: 4 * Math.PI,
  });

  cardRotationX.setKeys(cardRotationXKeys);

  // ROTATION Y
  const cardRotationY = new Animation(
    'cardRotationX',
    'rotation.y',
    60,
    Animation.ANIMATIONTYPE_FLOAT,
    Animation.ANIMATIONLOOPMODE_CONSTANT,
  );

  cardRotationY.setEasingFunction(easingFunction);

  const cardRotationYKeys = [];

  cardRotationYKeys.push({
    frame: 0,
    value: startRotation.y,
  });
  cardRotationYKeys.push({
    frame: 100,
    value: (5 * Math.PI) / 2,
  });

  cardRotationY.setKeys(cardRotationYKeys);

  // POSITION Y
  const cardPositionY = new Animation(
    'cardRotationX',
    'position.y',
    60,
    Animation.ANIMATIONTYPE_FLOAT,
    Animation.ANIMATIONLOOPMODE_CONSTANT,
  );

  cardPositionY.setEasingFunction(easingFunction);

  const cardPositionYKeys = [];

  cardPositionYKeys.push({
    frame: 0,
    value: startPosition.y,
  });
  cardPositionYKeys.push({
    frame: 100,
    value: (startPosition.y += 1.7),
  });

  cardPositionY.setKeys(cardPositionYKeys);

  // SCALE X
  const cardScalingX = new Animation(
    'cardRotationX',
    'scaling.x',
    60,
    Animation.ANIMATIONTYPE_FLOAT,
    Animation.ANIMATIONLOOPMODE_CONSTANT,
  );

  cardScalingX.setEasingFunction(easingFunction);

  const cardScalingXKeys = [];

  cardScalingXKeys.push({
    frame: 0,
    value: startScaling.x,
  });
  cardScalingXKeys.push({
    frame: 100,
    value: startScaling.x * 15,
  });

  cardScalingX.setKeys(cardScalingXKeys);

  // SCALE Y
  const cardScalingY = new Animation(
    'cardRotationX',
    'scaling.y',
    60,
    Animation.ANIMATIONTYPE_FLOAT,
    Animation.ANIMATIONLOOPMODE_CONSTANT,
  );

  cardScalingY.setEasingFunction(easingFunction);

  const cardScalingYKeys = [];

  cardScalingYKeys.push({
    frame: 0,
    value: startScaling.y,
  });
  cardScalingYKeys.push({
    frame: 100,
    value: startScaling.y * 15,
  });

  cardScalingY.setKeys(cardScalingYKeys);

  return [cardRotationX, cardRotationY, cardPositionY, cardScalingX, cardScalingY];
};

export { cardAnimation };
