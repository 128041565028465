import type { Camera, Scene as SceneType, ArcRotateCamera } from '@babylonjs/core';
import { Color3, MeshBuilder, StandardMaterial, VolumetricLightScatteringPostProcess, Texture } from '@babylonjs/core';

const godrays = (camera: ArcRotateCamera, scene: SceneType) => {
  const godraysRadiator = MeshBuilder.CreatePlane('radiator', { size: 0.6 }, scene);
  godraysRadiator.position.y += 1.1;
  godraysRadiator.rotation.x = (3 * Math.PI) / 2;
  const radiatorMaterial = new StandardMaterial('radiatorMaterial', scene);
  radiatorMaterial.diffuseColor = new Color3(0.098, 0.976, 1);
  radiatorMaterial.emissiveColor = new Color3(0.098, 0.976, 1);
  godraysRadiator.material = radiatorMaterial;

  const godrays = new VolumetricLightScatteringPostProcess(
    'godrays',
    1.0,
    camera as Camera,
    godraysRadiator,
    100,
    Texture.BILINEAR_SAMPLINGMODE,
    scene.getEngine(),
    false,
  );

  godrays.exposure = 0.1;
  godrays.decay = 0.9681;
  godrays.weight = 0.8;
  godrays.density = 0.996;

  return { godraysRadiator, godrays };
};

export { godrays };
