import type { Scene as SceneType } from '@babylonjs/core';
import { Color4, ParticleSystem, Texture, Vector3 } from '@babylonjs/core';

const baseParticleSystem = (scene: SceneType) => {
  const particle = new ParticleSystem('baseParticleSystem', 1000, scene);
  particle.particleTexture = new Texture('./assets/textures/flare.png');

  particle.emitter = new Vector3(0, 0.1, 0);
  particle.createDirectedCylinderEmitter(1.3, 0, 1, new Vector3(0, 1, 0), new Vector3(0, 1, 0));
  particle.updateSpeed = 0.01;
  particle.emitRate = 500;
  particle.minSize = 0.02;
  particle.maxSize = 0.05;
  particle.minLifeTime = 0.5;
  particle.maxLifeTime = 1.8;
  particle.maxEmitPower = 1.3;
  particle.minEmitPower = 0.5;
  particle.color1 = new Color4(0.141, 0.886, 1, 1);
  particle.color2 = new Color4(0, 0.4, 0.8, 1);

  particle.start();

  return particle;
};

const boxParticleSystem = (scene: SceneType) => {
  const particle = new ParticleSystem('boxParticleSystem', 1000, scene);
  particle.particleTexture = new Texture('./assets/textures/flare.png');
  const CONE_RADIUS = 0.5;
  const CONE_ANGLE = Math.PI / 3;

  particle.emitter = new Vector3(0, 0.5, 0);
  particle.createConeEmitter(CONE_RADIUS, CONE_ANGLE);
  particle.minEmitBox = new Vector3(-0.3, 0, -0.3);
  particle.maxEmitBox = new Vector3(0.3, 0.05, 0.3);
  particle.updateSpeed = 0.01;
  particle.emitRate = 300;
  particle.minSize = 0.03;
  particle.maxSize = 0.05;
  particle.minLifeTime = 0.1;
  particle.maxLifeTime = 1.5;
  particle.color1 = new Color4(1, 0, 0, 1);
  particle.color2 = new Color4(0, 1, 0, 1);
  particle.start();

  return particle as ParticleSystem;
};

export { baseParticleSystem, boxParticleSystem };
